import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControlLabel, Checkbox, Switch } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import Globais from "../../services/globais";
import FormControl from "@material-ui/core/FormControl";

import MenuItem from "@material-ui/core/MenuItem";

import Select from "@material-ui/core/Select";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import Input from "@material-ui/core/Input";
import { ContactsFilter } from "../ContactsFilter";

import PetsIcon from "@material-ui/icons/Pets";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  imageInput: {
    color: theme.palette.primary.main,
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),

  petName: Yup.string().when("selectedType", {
    is: "Tutor", // Condição para validar petName
    then: Yup.string().required("Nome do pet é obrigatório"), // Validação quando selectedType é 'Tutor'
    otherwise: Yup.string(), // Quando selectedType não é 'Tutor', petName não é obrigatório
  }),

  petType: Yup.string().when("selectedType", {
    is: "Tutor",
    then: Yup.string().required("Informe o tipo de pet."),
    otherwise: Yup.string(), // Quando selectedType não é 'Tutor', não é obrigatório
  }),
});

const ContactModal = ({
  open,
  onClose,
  contactId,
  ticketId,
  initialValues,
  onSave,
  ...props
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [linkedUserId, setLinkedUserId] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [phone, setMask] = useState("(99) 99999-9999");
  const [file, setFile] = useState();
  const [isGroup, setIsgroup] = useState(false);
  const { user } = useContext(AuthContext);

  const [selectedType, setSelectedType] = useState(""); // Estado para controlar pet e m.v

  const initialState = {
    name: "",
    number: "",
    email: "",
    petType: "",
    petName: "",
    collaboratorArea: "",
    selectedType: "",
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          if (
            data.linked_userId > 0 ||
            Globais.vinculation_Default === "true"
          ) {
            setLinkedUserId(true);
          } else {
            setLinkedUserId(false);
          }

          const contactDetails = extractContactDetails(data.name);
          setContact((prevState) => {
            return {
              ...prevState,
              ...data,
              ...contactDetails,
            };
          });
        }
        if (data.isGroup) {
          setIsgroup(true);
        } else {
          setIsgroup(false);
        }
        console.log("Dados do usuбrio editado: ", data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const extractContactDetails = (fullName) => {
    // Regex para Tutor
    const tutorRegex = /Tutor \((Canina|Felina)\) (.+)-(.+)/i;

    // Regex para M.V
    const mvRegex = /MV\s+(.+?)(?:\s+(\w+))?$/i;

    // Regex para Partner
    const partnerRegex = /Parceiro\s+([^ -]+(?: [^ -]+)*)\s*-\s*(.+)/i;

    // Regex para Supplier
    const supplierRegex = /Fornecedor\s+([^ -]+(?: [^ -]+)*)\s*-\s*(.+)/i;

    // Regex para Collaborator
    //const collaboratorRegex = /Vet Taboao\s+(.+?)\s*-\s*(.+)/;
    const collaboratorRegex = /Vet Taboao\s+(.+?)\s*-\s*(.+)/i;

    // Regex para OnDuty
    const onDutyRegex = /Plantao\s+(.+?)\s*-\s*(.+)/i;

    // Verifica se é um Tutor
    const tutorMatch = fullName.match(tutorRegex);
    if (tutorMatch) {
      setSelectedType("Tutor");
      return {
        petType: tutorMatch[1], // Canino ou Felino
        name: tutorMatch[2], // Nome do tutor
        petName: tutorMatch[3], // Nome do pet
        isMV: false,
        selectedType: "Tutor",
      };
    }

    // Verifica se é um M.V
    const mvMatch = fullName.match(mvRegex);
    if (mvMatch) {
      setSelectedType("MV");
      return {
        name: mvMatch[1].trim(),
        specialty: mvMatch[2] ? mvMatch[2].trim() : null,
      };
    }

    // Verifica se é um Partner
    const partnerMatch = fullName.match(partnerRegex);
    if (partnerMatch) {
      setSelectedType("Partner");
      return {
        petType: "", // Não aplicável para Partner
        name: partnerMatch[2],
        petName: "", // Não aplicável para Partner
        isMV: false,
        partnerCompany: partnerMatch[1] || "", // Adiciona a empresa se presente
      };
    }

    // Verifica se é um Supplier
    const supplierMatch = fullName.match(supplierRegex);
    if (supplierMatch) {
      setSelectedType("Supplier");
      return {
        petType: "", // Não aplicável para Supplier
        name: supplierMatch[2],
        petName: "", // Não aplicável para Supplier
        isMV: false,
        supplierCompany: supplierMatch[1] || "", // Adiciona a empresa se presente
      };
    }

    // Verifica se é um Collaborator
    const collaboratorMatch = fullName.match(collaboratorRegex);
    if (collaboratorMatch) {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };

      setSelectedType("Collaborator");
      return {
        petType: "", // Não aplicável para Collaborator
        name: collaboratorMatch[1],
        petName: "", // Não aplicável para Collaborator
        isMV: false,
        collaboratorArea: collaboratorMatch[2]
          ? capitalizeFirstLetter(collaboratorMatch[2].trim())
          : "",
      };
    }

    // Verifica se é um OnDuty
    const onDutyMatch = fullName.match(onDutyRegex);
    if (onDutyMatch) {
      setSelectedType("OnDuty");
      return {
        petType: "", // Não aplicável para OnDuty
        name: onDutyMatch[1],
        petName: "", // Não aplicável para OnDuty
        isMV: false,
        onDutyArea: onDutyMatch[2] || "", // Adiciona a área se presente
      };
    }

    // Caso nenhum padrão seja reconhecido
    return {
      name: fullName,
      isMV: false,
    };
  };

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleChange = (e) => {
    if (e.target.value === "linked_userId") {
      console.log("Salvando o contacts value: " + e.target.checked);
      setLinkedUserId(e.target.checked);
    }
  };

  const handleSaveContact = async (values) => {
    try {
      //Salvando os dados no contato do usuario que cadastrou e vinculando o contato ao usuario do sistema
      //A pedido do Flavio ADMIN ZAP

      if (linkedUserId) {
        values.linked_userId = user.id;
      } else {
        values.linked_userId = 0;
      }
      values.number = values.number
        .replace("+", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "");

      switch (selectedType) {
        case "Tutor":
          values.name =
            "Tutor " +
            "(" +
            values.petType +
            ") " +
            values.name +
            "-" +
            values.petName;
          break;
        case "MV":
          values.name =
            "MV " +
            values.name +
            (values.specialty ? ` ${values.specialty}` : "");
          break;
        case "Partner":
          values.name =
            "Parceiro " +
            (values.partnerCompany ? `${values.partnerCompany} -` : "") +
            " " +
            values.name;
          break;
        case "Supplier":
          values.name =
            "Fornecedor " +
            (values.supplierCompany ? `${values.supplierCompany} -` : "") +
            " " +
            values.name;
          break;
        case "Collaborator":
          values.name =
            "Vet Taboao " +
            values.name +
            " " +
            (values.collaboratorArea ? `- ${values.collaboratorArea}` : "");
          break;
        case "OnDuty":
          values.name =
            "Plantao " +
            values.name +
            (values.onDutyArea ? ` - ${values.onDutyArea}` : "");
          break;
      }

      // Transformar tudo em maisculas
      values.name = values.name.toUpperCase();

      // Removendo acentos.
      values.name = values.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (contactId) {
        //Implementando alterar Nome e Foto de um contato que seja Grupo
        if (contact.isGroup) {
          try {
            let data = {};

            const formData = new FormData();
            formData.append("medias", file);
            formData.append("name", values.name);
            formData.append("id", contactId);
            formData.append("userid", user.id);
            formData.append("ticketId", ticketId);
            formData.append("whatsappId", ticketId);
            formData.append("arrayUsers", selectedUser);

            await api.post(`/group/update`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            //	history.push(`/tickets/${ticketId}`);
            //	window.location.reload(true);

            //Implementado para verificar se o botao existe. Ideal para o Grupo ser editado pelo menu contato onde nгo tem o botгo
            // Evitar erros de getElementById not found.
            const buttonClick = document.getElementById("getMembers");
            if (document.getElementById("getMembers")) {
              buttonClick.click();
            }
            handleClose();
          } catch (err) {
            toastError(err);
          }
        } else {
          values.userId = user.id;
          await api.put(`/contacts/${contactId}`, values);
          window.location.reload(true);
        }
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedUser(tags);
  };

  const handleSelectTypeButtonClick = (type, setFieldValue) => {
    if (selectedType === type) {
      setSelectedType("");
      setFieldValue("selectedType", "");
    } else {
      setSelectedType(type);
      setFieldValue("selectedType", type);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />

                {isGroup && (
                  <Typography variant="subtitle1" gutterBottom>
                    Alterar Foto do Grupo
                  </Typography>
                )}

                <Can
                  role={user.profile}
                  perform="ticket-options:transferWhatsapp"
                  yes={() =>
                    isGroup && (
                      <Input
                        fullWidth
                        type="file"
                        accept="image/*"
                        className={classes.imageInput}
                        filename={file}
                        onChange={(e) => setFile(e.target.files[0])}
                        placeholder="Imagem do Grupo"
                      />
                    )
                  }
                />
                {/*<Field
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									name="number"
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
									placeholder="5513912344321"
									variant="outlined"
									margin="dense"
								/>*/}
                {!contact.isGroup && (
                  <PhoneInput
                    country={"br"}
                    value={values.number}
                    onChange={(phone) => (values.number = phone)}
                    fullWidth
                  />
                )}

                {isGroup && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 20 }}
                  >
                    <ContactsFilter onFiltered={handleSelectedTags} />
                  </FormControl>
                )}

                {!contact.isGroup && (
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      placeholder="Email address"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    />
                  </div>
                )}
                {Globais.vinculation_Default === "true" &&
                  contactId === null &&
                  user.profile.toUpperCase() !== "USER" && (
                    <FormControlLabel
                      value="linked_userId"
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="linked_userId"
                          checked={true}
                          onChange={handleChange}
                        />
                      }
                      label={"Vinculado ao login"}
                    />
                  )}
                {Globais.vinculation_Default === "true" &&
                  contactId != null &&
                  user.profile.toUpperCase() !== "USER" && (
                    <FormControlLabel
                      value="linked_userId"
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="linked_userId"
                          checked={linkedUserId}
                          onChange={handleChange}
                        />
                      }
                      label={"Vinculado ao login"}
                    />
                  )}
                {Globais.vinculation_Default === "false" &&
                  contactId === null &&
                  user.profile.toUpperCase() !== "USER" && (
                    <FormControlLabel
                      value="linked_userId"
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="linked_userId"
                          checked={false}
                          onChange={handleChange}
                        />
                      }
                      label={"Vinculado ao login"}
                    />
                  )}
                {Globais.vinculation_Default === "false" &&
                  contactId != null &&
                  user.profile.toUpperCase() !== "USER" && (
                    <FormControlLabel
                      value="linked_userId"
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="linked_userId"
                          checked={linkedUserId}
                          onChange={handleChange}
                        />
                      }
                      label={"Vinculado ao login"}
                    />
                  )}
                {!isGroup && (
                  <Typography
                    style={{ marginBottom: 8, marginTop: 12 }}
                    variant="subtitle1"
                  >
                    {i18n.t("contactModal.form.extraInfo")}
                  </Typography>
                )}

                {/* Botхes para escolher entre Pet e M.V */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    columnGap: "10px",
                    rowGap: "6px",
                  }}
                >
                  <Button
                    variant={
                      selectedType === "Tutor" ? "contained" : "outlined"
                    }
                    color={selectedType === "Tutor" ? "primary" : "default"}
                    onClick={() =>
                      handleSelectTypeButtonClick("Tutor", setFieldValue)
                    }
                    fullWidth
                  >
                    <PetsIcon style={{ marginRight: "8px", fontSize: 14 }} />
                    Tutor
                  </Button>

                  <Button
                    variant={selectedType === "MV" ? "contained" : "outlined"}
                    color={selectedType === "MV" ? "primary" : "default"}
                    onClick={() =>
                      handleSelectTypeButtonClick("MV", setFieldValue)
                    }
                    fullWidth
                  >
                    MV
                  </Button>

                  <Button
                    variant={
                      selectedType === "Partner" ? "contained" : "outlined"
                    }
                    color={selectedType === "Partner" ? "primary" : "default"}
                    onClick={() =>
                      handleSelectTypeButtonClick("Partner", setFieldValue)
                    }
                    fullWidth
                  >
                    Parceiro
                  </Button>

                  <Button
                    variant={
                      selectedType === "Supplier" ? "contained" : "outlined"
                    }
                    color={selectedType === "Supplier" ? "primary" : "default"}
                    onClick={() =>
                      handleSelectTypeButtonClick("Supplier", setFieldValue)
                    }
                    fullWidth
                  >
                    Fornecedor
                  </Button>

                  <Button
                    variant={
                      selectedType === "Collaborator" ? "contained" : "outlined"
                    }
                    color={
                      selectedType === "Collaborator" ? "primary" : "default"
                    }
                    onClick={() =>
                      handleSelectTypeButtonClick("Collaborator", setFieldValue)
                    }
                    fullWidth
                  >
                    Colaborador
                  </Button>

                  <Button
                    variant={
                      selectedType === "OnDuty" ? "contained" : "outlined"
                    }
                    color={selectedType === "OnDuty" ? "primary" : "default"}
                    onClick={() =>
                      handleSelectTypeButtonClick("OnDuty", setFieldValue)
                    }
                    fullWidth
                  >
                    Plantonista
                  </Button>
                </div>

                {/* Section tutor */}
                {!isGroup && selectedType === "Tutor" && (
                  <>
                    {/* Campo para o nome do pet */}
                    <Field
                      as={TextField}
                      label="Nome do Pet"
                      name="petName"
                      error={touched.petName && Boolean(errors.petName)}
                      helperText={touched.petName && errors.petName}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />

                    {/* Botхes para escolher o tipo de pet */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginY={2}
                    >
                      <Button
                        variant={
                          values.petType.toLowerCase() === "canina"
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          values.petType.toLowerCase() === "canina"
                            ? "primary"
                            : "default"
                        }
                        onClick={() =>
                          setFieldValue(
                            "petType",
                            values.petType.toLowerCase() === "canina"
                              ? ""
                              : "canina"
                          )
                        }
                        fullWidth
                        style={{ marginRight: "8px" }}
                      >
                        Canina
                      </Button>
                      <Button
                        variant={
                          values.petType.toLowerCase() === "felina"
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          values.petType.toLowerCase() === "felina"
                            ? "primary"
                            : "default"
                        }
                        onClick={() =>
                          setFieldValue(
                            "petType",
                            values.petType.toLowerCase() === "felina"
                              ? ""
                              : "felina"
                          )
                        }
                        fullWidth
                        style={{ marginLeft: "8px" }}
                      >
                        Felina
                      </Button>
                    </Box>
                  </>
                )}

                {/* Section do M.V */}
                {!isGroup && selectedType === "MV" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Especialidade"
                      name="specialty"
                      error={touched.specialty && Boolean(errors.specialty)}
                      helperText={touched.specialty && errors.specialty}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                )}

                {/* Section Partner */}
                {!isGroup && selectedType === "Partner" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Empresa Parceira"
                      name="partnerCompany"
                      error={touched.specialty && Boolean(errors.specialty)}
                      helperText={touched.specialty && errors.specialty}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                )}

                {/* Section Supplier */}
                {!isGroup && selectedType === "Supplier" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Empresa"
                      name="supplierCompany"
                      error={touched.specialty && Boolean(errors.specialty)}
                      helperText={touched.specialty && errors.specialty}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                )}

                {/* Section Collaborator */}
                {!isGroup && selectedType === "Collaborator" && (
                  <div style={{ marginTop: "10px" }}>
                    <Field
                      as={Select}
                      label="Area"
                      name="collaboratorArea"
                      error={touched.onDutyArea && Boolean(errors.onDutyArea)}
                      helperText={touched.onDutyArea && errors.onDutyArea}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="">Selecione a área</MenuItem>
                      <MenuItem value="Gestao">Gestão</MenuItem>
                      <MenuItem value="Recepcao">Recepção</MenuItem>
                      <MenuItem value="Clinica">Clínica</MenuItem>
                      <MenuItem value="Enfermagem">Enfermagem</MenuItem>
                      <MenuItem value="Internacao">Internação</MenuItem>
                      <MenuItem value="Admin">Administração</MenuItem>
                    </Field>
                  </div>
                )}

                {/* Section  Plantonista*/}
                {!isGroup && selectedType === "OnDuty" && (
                  <div>
                    <Field
                      as={TextField}
                      label="Area"
                      name="onDutyArea"
                      error={touched.specialty && Boolean(errors.specialty)}
                      helperText={touched.specialty && errors.specialty}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                )}

                {!isGroup && (
                  <FieldArray name="extraInfo">
                    {({ push, remove }) => (
                      <>
                        {values.extraInfo &&
                          values.extraInfo.length > 0 &&
                          values.extraInfo.map((info, index) => (
                            <div
                              className={classes.extraAttr}
                              key={`${index}-info`}
                            >
                              <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.extraName")}
                                name={`extraInfo[${index}].name`}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                              />
                              <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.extraValue")}
                                name={`extraInfo[${index}].value`}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                              />
                              <IconButton
                                size="small"
                                onClick={() => remove(index)}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </div>
                          ))}
                        <div className={classes.extraAttr}>
                          <Button
                            style={{ flex: 1, marginTop: 8 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => push({ name: "", value: "" })}
                          >
                            {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
